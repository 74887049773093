"use client";

import { RefObject, useCallback, useEffect } from "react";

export function useOnClickOutside(rootRef: RefObject<Element>, handler: (ev: Event) => void): void {
  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (!rootRef.current || rootRef.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    },
    [rootRef, handler],
  );

  useEffect(() => {
    window.addEventListener("click", handleClickOutside, true);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [rootRef, handleClickOutside]);
}
