import Image from "next/image";

import { FC } from "react";

interface Props {
  image: string;
  width?: number;
  height?: number;
  className?: string;
}

export const CampaignPicture: FC<Props> = ({ image, width, height, className }) => (
  <Image
    src={image?.length ? image : "/images/logo-short.svg"}
    alt="campaign pic"
    style={{
      objectFit: image?.length ? "cover" : "contain",
      inset: image?.length ? 0 : "8px",
    }}
    className={className}
    width={width}
    height={height}
    priority
  />
);
