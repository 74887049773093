"use client";

import { useEffect } from "react";

export class Unmounted {
  message = "Unmounted";
}

type Awaitable<T> = Promise<T>;
export type Awaiter = <T>(awaitable: Awaitable<T>) => Promise<T>;
type Callback = (
  awaitable: Awaiter,
  registerDestructor: (destructor: () => void) => void,
) => Promise<unknown>;
type OnUnmount = (reason?: Unmounted) => void;

export function useEffectAsync(callback: Callback, dependencies?: readonly unknown[]): void {
  useEffect(() => {
    let tooLateToRegisterDestructor = false;
    let onDestroy: (() => void) | undefined;

    const setDestructor = (newOnDestroy: () => void): void => {
      if (tooLateToRegisterDestructor) {
        throw new Error("Cannot register destructor after first asynchronous operation");
      }
      onDestroy = newOnDestroy;
    };

    let onUnmount: OnUnmount | undefined;
    const unmountPromise = new Promise((_, reject) => {
      onUnmount = reject;
    });

    const awaiter = <T>(awaitable: Awaitable<T>): Promise<T> => {
      tooLateToRegisterDestructor = true;
      return Promise.race([awaitable, unmountPromise as Promise<T>]);
    };

    unmountPromise.catch(() => {});

    callback(awaiter, setDestructor).then(
      () => {
        onUnmount = undefined;
      },
      (e) => {
        if (!(e instanceof Unmounted)) {
          throw e;
        }
      },
    );

    return () => {
      if (onUnmount) {
        onUnmount(new Unmounted());
      }

      if (onDestroy) {
        onDestroy();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
